import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useQuery } from 'react-query';
import filter from 'lodash/filter';
import head from 'lodash/head';
import { useFetch } from '../request';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import Loader from '../components/common/Loader';
import Flex from '../components/common/Flex';
import AddressCard from '../components/driver/AddressCard';
import GeneralCard from '../components/driver/GeneralCard';
import GroupSponsorCard from '../components/driver/GroupSponsorCard';
import FeedbackCard from '../components/driver/FeedbackCard';
import SelectGroupModal from '../components/SelectGroupModal';
import FormModal from '../components/FormModal';
import DriverForm from '../components/form/DriverForm';
import ButtonIcon from '../components/common/ButtonIcon';
import { useStoreState as useSettingStoreState } from '../stores/SettingsStore';
import VehicleCard from '../components/trip/VehicleCard';

const DriverDetails = ({ match }) => {
  const { id } = match.params;
  const [showEditModal, setShowEditModal] = useState(false);
  const [relation, setRelation] = useState(null);
  const [{ isAdmin = false }] = useSettingStoreState('currentUser');
  const fetch = useFetch();
  const { data: driver, isLoading, isError, error, refetch } = useQuery(`drivers/${id}`, fetch, {
    enabled: id
  });
  if (!id) {
    return <Redirect to={`/drivers`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  return (
    <>
      <WidgetsSectionTitle
        title="Driver"
        className="mb-4 mt-3 align-items-center"
        icon="user-astronaut"
        buttons={
          <>
            {isAdmin && (
              <>
                <ButtonIcon
                  color="falcon-default"
                  size="sm"
                  icon="pen"
                  className="fs--1 text-600 px-2"
                  onClick={() => setShowEditModal(driver)}
                  iconClassName="fs--1"
                  title="Edit entry"
                />
                <ButtonIcon
                  disabled={!driver?.relation}
                  color="falcon-default"
                  size="sm"
                  icon="users"
                  className="fs--1 text-600 px-2 ml-2"
                  onClick={() => setRelation(driver.relation)}
                  iconClassName="fs--1"
                  title="Group permission"
                />
              </>
            )}
            <ButtonIcon
              disabled={!driver?.relation}
              color="falcon-default"
              size="sm"
              icon="chart-line"
              className="fs--1 text-600 px-2 ml-2"
              iconClassName="fs--1"
              tag={Link}
              to={`/drivers/${id}/report`}
              title="Behavior Report"
            />
          </>
        }
      />
      <div className="card-deck align-items-stretch mb-3">
        <Card>
          <CardBody tag={Flex} column justify="center">
            <Row className="mb-1">
              <Col xs={6} md={4} className="text-right text-500">
                Firstname
              </Col>
              <Col className="demo-blur">{driver?.firstName || <i>Unknown</i>}</Col>
            </Row>
            <Row className="mb-1">
              <Col xs={6} md={4} className="text-right text-500">
                Surname
              </Col>
              <Col className="demo-blur">{driver?.lastName || <i>Unknown</i>}</Col>
            </Row>
            <Row className="mb-1">
              <Col xs={6} md={4} className="text-right text-500">
                E-Mail
              </Col>
              <Col className="demo-blur">{driver?.email || <i>Unknown</i>}</Col>
            </Row>
            <Row>
              <Col xs={6} md={4} className="text-right text-500">
                Phone
              </Col>
              <Col className="demo-blur">{driver?.phone || <i>Unknown</i>}</Col>
            </Row>
          </CardBody>
        </Card>
        <VehicleCard deviceId={driver?.relation?.deviceId} vehicle={driver?.relation?.vehicle} className="h-100" />
      </div>

      <AddressCard
        className="mb-3"
        type="home"
        address={head(filter(driver.addresses, a => a.type === 'home'))}
        driverId={driver.id}
      />

      <AddressCard
        className="mb-3"
        type="work"
        address={head(filter(driver.addresses, a => a.type === 'work'))}
        driverId={driver.id}
      />

      <div className="card-deck align-items-stretch mb-3">
        <GeneralCard user={driver} />
        <GroupSponsorCard relation={driver?.relation} sponsor={driver?.sponsor} />
      </div>

      <FeedbackCard driver={driver} />
      <SelectGroupModal
        relation={relation}
        onClose={newGroupId => {
          if (newGroupId !== false) {
            refetch();
          }
          setRelation(null);
        }}
      />
      <FormModal
        open={!!showEditModal}
        form={DriverForm}
        data={driver}
        title="Driver"
        onClose={newDriver => {
          if (newDriver) {
            refetch();
          }
          setShowEditModal(false);
        }}
      />
    </>
  );
};

export default DriverDetails;
