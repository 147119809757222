import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import Dashboard from '../pages/Dashboard';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/Footer';
import NavbarTop from '../components/navbar/NavbarTop';
import Trips from '../pages/Trips';
import Devices from '../pages/Devices';
import Vehicles from '../pages/Vehicles';
import DeviceDetails from '../pages/DeviceDetails';
import VehicleDetails from '../pages/VehicleDetails';
import TripDetails from '../pages/TripDetails';
import Drivers from '../pages/Drivers';
import DriverDetails from '../pages/DriverDetails';
import Users from '../pages/Users';
import Groups from '../pages/Groups';
import UserDetails from '../pages/UserDetails';
import DriverReport from '../pages/DriverReport';
import ChargingReport from '../pages/ChargingReport';
import GroupDetails from '../pages/GroupDetails';
import ApiDoc from '../pages/ApiDoc';
import Sponsors from '../pages/Sponsors';
import ChargingElcinstation from '../pages/ChargingElcinstation';
import ActivityIssues from '../pages/ActivityIssues';
import { useStoreState } from '../stores/SettingsStore';
import ChargingSpeed from '../pages/control-center/ChargingSpeed';
import ChargingDifference from '../pages/control-center/ChargingDifference';
import RangeCalculation from '../pages/control-center/RangeCalculation';
import DistanceDifference from '../pages/control-center/DistanceDifference';
import ScoreDifference from '../pages/control-center/ScoreDifference';
import StateOfHealth from '../pages/control-center/StateOfHealth';
import SocRange from '../pages/control-center/SocRange';
import EventCharts from '../pages/control-center/EventCharts';
import Feedback from '../pages/Feedback';

const DashboardLayout = ({ location }) => {
  const [isDemoMode] = useStoreState('demoMode');

  return (
    <div className={classNames('container-fluid', { 'demo-mode': isDemoMode })}>
      <NavbarVertical />
      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/activities/:id" exact component={TripDetails} />
          <Route path="/activities" exact component={Trips} />
          <Route path="/charging-report" exact component={ChargingReport} />
          <Route path="/devices/:id" exact component={DeviceDetails} />
          <Route path="/devices" exact component={Devices} />
          <Route path="/vehicles/:id" exact component={VehicleDetails} />
          <Route path="/vehicles" exact component={Vehicles} />
          <Route path="/drivers/:id/report" exact component={DriverReport} />
          <Route path="/drivers/:id" exact component={DriverDetails} />
          <Route path="/drivers" exact component={Drivers} />
          <Route path="/users/:id" exact component={UserDetails} />
          <Route path="/users" exact component={Users} />
          <Route path="/sponsors" exact component={Sponsors} />
          <Route path="/groups/:id" exact component={GroupDetails} />
          <Route path="/groups" exact component={Groups} />
          <Route path="/docs" exact component={ApiDoc} />
          <Route path="/charging-elcinstation" exact component={ChargingElcinstation} />
          <Route path="/activity-issues" exact component={ActivityIssues} />
          <Route path="/control-center/charging-speed" exact component={ChargingSpeed} />
          <Route path="/control-center/range-calculation" exact component={RangeCalculation} />
          <Route path="/control-center/charging-difference" exact component={ChargingDifference} />
          <Route path="/control-center/distance-difference" exact component={DistanceDifference} />
          <Route path="/control-center/score-difference" exact component={ScoreDifference} />
          <Route path="/control-center/soh" exact component={StateOfHealth} />
          <Route path="/control-center/soc-range" exact component={SocRange} />
          <Route path="/control-center/event-charts" exact component={EventCharts} />
          <Route path="/feedback" exact component={Feedback} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
